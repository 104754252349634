<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { computed } from 'vue'
import { useHeaderMainStore } from '~/store'
import { useGidashQuery } from '~/composables/useGidashQuery'
import TheBestFriendCountry from '~/components/Country/TheBestFriendCountry.vue'
// import AnimatedPlaceholder from '~/components/AnimatedPlaceholder/AnimatedPlaceholder.vue'

const { t } = useI18n()
const store = useHeaderMainStore()
const route = useRoute()
// const router = useRouter()
const slug = ref(route.query.region || 'global')
const response = ref({ data: null })

// watch(
//     () => route,
//     async () => {
//         await routeToBestFriendCountry()
//     },
//     { deep: true, immediate: true }
// )

// watch(
//     () => store.selectedRegion,
//     async () => {
//         await routeToBestFriendCountry()
//     },
//     { deep: true, immediate: true }
// )

// async function routeToBestFriendCountry() {
//     if (
//         !store.isLoadingCountries &&
//         store.selectedRegion &&
//         store.selectedRegion.countryType?.charCode === 'BF'
//     ) {
//         console.log('redirecting...')
//         await router.push({
//             name: `countryslug-regions___${locale.value}`,
//             params: { countryslug: store.selectedRegion.slug },
//             query: { region: store.selectedRegion?.slug }
//         })
//     }
// }

await getData()

async function getData() {
    const res = await useHttpClient(
        `/content/country/${slug.value}/main`,
        {
            query: {
                allCountry: store.selectedRegion?.slug === 'global'
            }
        }
    )

    response.value.data = res.data
}

watch(
    () => route.query,
    async newVal => {
        slug.value = newVal.region || 'global'
        await getData()
        // refresh()
    },
    { deep: true }
)
const pageData = computed(() => response.value.data)

const introBanners = computed(() =>
    store.stories?.map(item => ({
        ...item,
        link: `/${
            item.contentType.gname?.toLowerCase() !== 'news'
                ? item.contentType.gname?.toLowerCase() + 's'
                : item.contentType.gname?.toLowerCase()
        }/${item.slug}`,
        imageHide: item?.imageHide,
        title: item.translations[0]?.name
    }))
)

const GIDASH_QUERY = '131'
const responseGidash = await useGidashQuery(GIDASH_QUERY)
const statYears = computed(
    () =>
        new Date().getFullYear() -
        new Date('01/01/1992').getFullYear()
)
const statsData = computed(
    () => responseGidash?.value?.query_result?.data.rows[0]
)

const stats = ref([
    {
        img: 'countries',
        number: statsData.value?.country,
        text: t('homePage.stats.countriesOfPresence')
    },
    {
        img: 'years',
        number: statYears.value,
        text: t('homePage.stats.experience')
    },
    {
        img: 'professionals',
        number: statsData.value?.lawyer,
        text: t('homePage.stats.professionals')
    },

    {
        img: 'clients',
        number: statsData.value?.client,
        text: t('homePage.stats.clients')
    },
    {
        img: 'projects',
        number: statsData.value?.matter,
        text: t('homePage.stats.projects')
    }
])

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const indicator = useLoadingIndicator()
const aboutRegion = computed(() =>
    pageData.value
        ? [
              {
                  id: pageData.value?.country?.id,
                  creationDate: pageData.value?.country?.creationDate,
                  title:
                      pageData.value?.country?.slug !== 'global'
                          ? pageData.value?.country?.translations[0]
                                .name
                          : 'Global International',
                  // title: 'GRATA International Beijing together with Zhongman Petroleum and Natural    Gas (Gulf) FZE visited Astana International Financial Center',
                  shortDescription:
                      pageData.value?.country?.translations[0]
                          ?.description ||
                      pageData.value?.country?.translations[0]
                          ?.shortDescription,
                  to: `${pageData.value?.country?.slug}/regions`,
                  keywords:
                      pageData.value?.country?.translations[0]
                          .keywords,
                  imageLink: pageData.value?.country?.imageLink
              }
          ]
        : [{}]
)

const title = computed(() =>
    route.params.countryslug === 'global'
        ? 'Global International'
        : pageData.value?.country?.translations[0]?.name
)

const keywords = computed(
    () =>
        pageData.value?.country?.translations[0]?.keywords ||
        title.value
)
const descriptionMeta = computed(
    () =>
        pageData.value?.country?.translations[0]?.meta || title.value
)

function setPageHead() {
    useHead({
        title: title.value,
        meta: [
            {
                name: 'keywords',
                content: keywords.value || 'default keywords'
            },
            {
                name: 'description',
                content: descriptionMeta.value
            }
        ]
    })
}

setPageHead()
watchEffect(() => {
    if (pageData.value) {
        setPageHead()
    }
})
const isCountryGlobal = computed(
    () => store.selectedRegion?.slug === 'global'
)

const countriesWithKeyContacts = ref()
if (isCountryGlobal.value) {
    const { data } = await useHttpClient(
        '/content/countryKeyContacts'
    )
    if (Array.isArray(data?.value)) {
        countriesWithKeyContacts.value = data?.value
            ?.map((c: any) => ({
                id: c.id,
                name: c.translations[0].name,
                keyContacts: mapKeyContact(c.keyContacts)
            }))
            .sort((a: any, b: any) => {
                return a.name > b.name ? 1 : -1
            })
        countriesWithKeyContacts.value = [
            {
                id: store.selectedRegion?.id,
                name: t('chooseCountry'),
                keyContacts: mapKeyContact(
                    pageData.value?.country?.keyContacts
                ),
                hideInList: true
            },
            ...countriesWithKeyContacts.value
        ]
    }
}

const selectedCountry = ref()
const officesWithKeyContacts = computed(() => {
    if (!isCountryGlobal.value) {
        const offices =
            pageData.value?.offices?.map((o: any) => ({
                id: o.id,
                name: o.translations[0]?.name,
                keyContacts: mapKeyContact(o.keyContacts)
            })) || []
        offices.unshift({
            id: selectedCountry.value?.id,
            name: t('chooseOffice'),
            keyContacts: mapKeyContact(
                pageData.value?.country?.keyContacts
            ),
            hideInList: true
        })
        return offices
    }
    return []
})

const regionKeyContacts = computed(() =>
    mapKeyContact(pageData.value?.country?.keyContacts)
)

function mapKeyContact(keyContacts: any) {
    if (!Array.isArray(keyContacts)) return []
    return keyContacts.map(
        (item: any) =>
            ({
                id: item.id,
                name: item.keyContact?.translations?.length
                    ? item.keyContact?.translations[0]?.name
                    : '',
                city: item.keyContact?.translations?.length
                    ? item.keyContact?.translations[0]?.city
                    : '',
                jobTitle: item.keyContact?.translations?.length
                    ? item.keyContact?.translations[0]?.jobTitle
                    : '',
                firstEmail: item.keyContact.firstEmail || 'no email',
                firstPhoneNumber:
                    item.keyContact.firstPhoneNumber || 'no number',
                imageLink: item.keyContact.imageLink || 'no image',
                slug: item.keyContact.slug || 'no-slug',
                office: item.keyContact.office
            }) || []
    )
}

const newsContentSlides = computed(
    () =>
        pageData.value?.news?.map(item => ({
            ...item,
            title:
                item.translations[0]?.name || 'no translations name',
            to: `/news/${item.slug}`,
            toTitle: '/insights',
            hash: '#news',
            shortDescription:
                item.translations[0]?.description ||
                item.translations[0]?.shortDescription
        })) || []
)

const publicationsContentSlides = computed(
    () =>
        pageData.value?.publications?.map(item => ({
            ...item,
            title:
                item.translations[0]?.name || 'no translations name',
            to: `/publications/${item.slug}`,
            toTitle: '/insights',
            hash: '#publications',
            shortDescription:
                item.translations[0]?.description ||
                item.translations[0]?.shortDescription
        })) || []
)

const eventsContentSlides = computed(
    () =>
        pageData.value?.events?.map(item => ({
            ...item,
            to: `/events/${item.slug}`,
            toTitle: '/insights',
            hash: '#events',
            title:
                item.translations[0]?.name || 'no translations name',
            shortDescription:
                item.translations[0]?.description ||
                item.translations[0]?.shortDescription
        })) || []
)

const newsletterBanners = computed(
    () =>
        pageData.value?.newsletters?.map(item => ({
            ...item,
            to: `/newsletters/${item.slug}`,
            toTitle: '/insights',
            hash: '#newsletters',
            title:
                item.translations[0]?.name || 'no translations name'
        })) || []
)

const reverseEvent = computed(() => {
    if (
        (newsContentSlides.value?.length &&
            !publicationsContentSlides.value.length) ||
        (!newsContentSlides.value?.length &&
            publicationsContentSlides.value.length)
    ) {
        return true
    }

    return false
})
const i18n = useI18n()
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const updateKey = computed(() =>
    route.query.region
        ? route.query.region + '-' + i18n.locale.value
        : `global-${i18n.locale.value}`
)

const aboutTitle = computed(
    () =>
        `GRATA International ${
            pageData.value?.country?.slug !== 'global'
                ? aboutRegion.value[0].title
                : ''
        }`
)

const isBestFriendCountry = computed(
    () => store.selectedRegion.countryType?.charCode === 'BF'
)
const { isLoading } = useLoadingIndicator()
</script>
<template>
    <div v-if="isLoading || store.isLoadingCountries" class="loader">
        <AppLoader />
    </div>
    <template v-else>
        <main
            v-if="
                !isBestFriendCountry &&
                !store.isLoadingCountries &&
                !isLoading
            "
            class="home-page"
        >
            <SliderHomeIntro
                v-if="introBanners?.length"
                :banners="introBanners"
            />

            <!-- Conatiner -->
            <div class="container">
                <HomeStatistics :stats="stats" />
            </div>

            <div class="home-page__slider-wrapper">
                <div class="home-page__container">
                    <SliderContent
                        :banners="aboutRegion"
                        reverse
                        :title="aboutTitle"
                        :show-sub-title="false"
                        text-block-background-image="/images/slider/bg-slide-about.webp"
                    />
                    <SliderContent
                        :banners="newsContentSlides"
                        :title="$t('news.title')"
                        text-block-background-image="/images/slider/bg-slide-news.webp"
                    />
                    <SliderContent
                        :banners="publicationsContentSlides"
                        :title="t('publications.title')"
                        :reverse="!!newsContentSlides?.length"
                        text-block-background-image="/images/slider/bg-slide-publications.webp"
                    />
                    <SliderContent
                        :banners="eventsContentSlides"
                        :title="t('events.title')"
                        :reverse="reverseEvent"
                        text-block-background-image="/images/slider/bg-slide-events.webp"
                    />
                </div>
            </div>

            <!-- Slider newsletter -->
            <SliderNewsletter :banners="newsletterBanners" />

            <!-- Feedback container -->
            <div class="feedback">
                <div class="container">
                    <div class="feedback-container">
                        <FeedbackForm from="index" />
                        <FeedbackSlider
                            :items="regionKeyContacts"
                            :countries="countriesWithKeyContacts"
                            :offices="officesWithKeyContacts"
                            data-aos="fade-left"
                        />
                    </div>
                </div>
            </div>
        </main>
        <TheBestFriendCountry
            v-else-if="!store.isLoadingCountries && !isLoading"
            :page-data="pageData"
        />
    </template>
</template>

<style scoped lang="scss">
.loader {
    height: 100vh;
    width: 100%;
}

.home-page__slider-wrapper {
    border-radius: 30px;
    overflow: hidden;
    padding: 0;
    margin: 0 auto;
    @include sm-block {
        border-radius: 0;
    }
}

.home-page__container {
    padding: 0;
    @include sm-block {
        padding: 0 15px;
    }
}
</style>
