<script lang="ts" setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useHeaderMainStore } from '~/store'
import type { Language, Region } from '~/types'
import { useGetDepartments } from '~/composables/useGetDepartments'
import { useWindowResize } from '~/composables/useWindowResize'

type SwitcherPosition = 'header' | 'footer'

interface Props {
    position?: SwitcherPosition
}

withDefaults(defineProps<Props>(), {
    position: 'header'
})
const openLanguageList = ref(false)

const i18n = useI18n()
const route = useRoute()
const store = useHeaderMainStore()

const languageSwitcherRef = ref()

onMounted(() => {
    if (languageSwitcherRef.value) {
        useDetectOutsideClick(languageSwitcherRef, () => {
            openLanguageList.value = false
        })
        const languagePopup =
            document?.querySelector('.language-popup')
        if (languagePopup) {
            addHidePopupClass()
        }
    }
})

const term = ref('')
const regions = computed(() =>
    !term.value
        ? store.countries
        : store.countries.filter(
              c =>
                  c.name?.toLowerCase().includes(term.value) ||
                  c.countryLanguages.some(ct =>
                      ct.localLanguage
                          ?.toLowerCase()
                          ?.includes(term.value?.toLowerCase())
                  )
          )
)

const { width } = useWindowResize()
const splitArrayIntoColumns = (array: any[], numColumns: number) => {
    if (!Array.isArray(array)) return []
    const columns = Array.from({ length: numColumns }, () => [])

    array.forEach((item, index) => {
        const columnIndex = index % numColumns // равномерное распределение по колонкам
        columns[columnIndex].push(item)
    })

    return columns
}
const numColumns = computed(() => {
    if (width?.value <= 580) {
        return 1
    }
    return width?.value < 768 ? 3 : 4
})
const regionsAsColumns = computed(() => {
    return splitArrayIntoColumns(regions.value, numColumns.value)
})

const router = useRouter()
const selectedLanguageCode = computed(
    () => i18n.locale.value.toUpperCase() || 'EN'
)

const selectedLocalLanguageName = computed(
    () =>
        store.selectedRegion?.countryLanguages.find(
            cl =>
                cl.languageCharCode ===
                i18n.locale.value.toUpperCase()
        )?.localLanguage
)
const { getDepartments } = useGetDepartments()

const setSelectedRegionFromCountries = (slug?: string) => {
    // if (route.query.region) {
    //     store.selectedRegion = store.countries?.find(
    //         c => c.slug === route.query.region
    //     ) as Region

    //     console.log(
    //         'Has region from query',
    //         route.query.region,
    //         store.countries,
    //         store.countries?.find(c => c.slug === route.query.region)
    //     )
    // } else {
    store.selectedRegion = store.countries?.find(
        c => c.slug === slug
    ) as Region
    // }
}
async function selectRegion(
    region: Region | null = store.selectedRegion,
    lang: Language
) {
    if (!region) {
        region = store.selectedRegion
    }
    console.log('Region lang', region, lang)
    openLanguageList.value = false
    // selectedLocalLanguageName.value = lang.localLanguage
    const localeCode = lang?.languageCharCode?.toLowerCase() || 'en'

    openLanguageList.value = false
    const isCountryChanged =
        store.selectedRegion?.slug !== region.slug
    // const isLocaleChanged = i18n.locale.value !== lang.charCode
    const switchLocalePath = useSwitchLocalePath()
    const isBestFriendCountry = region.countryType?.charCode === 'BF'

    if (isCountryChanged && region?.slug !== 'global') {
        i18n.locale.value = localeCode
        try {
            await router.push({
                name: `index___${localeCode}`,
                query: { region: region?.slug }
            })

            // store.$patch({ selectedRegion: region })
            // if (store?.selectedRegion) {
            //     store.selectedRegion = region
            // }
            await store.getHeaderData(
                true,
                region.slug ||
                    (route.query.slug as string) ||
                    'global'
            )

            setSelectedRegionFromCountries(
                region.slug ||
                    (route.query.slug as string) ||
                    'global'
            )

            await getDepartments(store.selectedRegion?.id || -1)
        } catch (e) {
            console.error('ERROR WHEN CHANGE LOCATION', e)
        }
        return
    }

    if (region?.slug === 'global') {
        const routeObj = isCountryChanged
            ? { name: `index___${localeCode}` }
            : { path: switchLocalePath(localeCode) }
        try {
            await router.push({ ...routeObj, query: {} })
            // if (store?.selectedRegion) {
            //     store.selectedRegion = region
            // }
            await store.getHeaderData(true, region.slug)
            setSelectedRegionFromCountries('global')
            await getDepartments(store.selectedRegion?.id || -1)
        } catch (e) {
            console.error('ERROR WHEN CHANGE LOCATION', e)
        }
        if (isCountryChanged) {
            i18n.locale.value = localeCode
        }

        return
    }

    if (!isBestFriendCountry) {
        try {
            await router.push({
                path: switchLocalePath(localeCode),
                query: {
                    ...route.query,
                    region: region?.slug
                }
            })

            await store.getHeaderData(false, region.slug)
            setSelectedRegionFromCountries(region.slug)
            await getDepartments(store.selectedRegion?.id || -1)
        } catch (e) {
            console.error('ERROR WHEN CHANGE LOCATION', e)
        }
    }
}

const languageWithCountry = computed(() => {
    return i18n.locale.value === 'ar'
        ? `${selectedLocalLanguageName.value} -
        ${store?.selectedRegion?.name}`
        : `${store?.selectedRegion?.name} -
        ${selectedLocalLanguageName.value}`
})

function addHidePopupClass() {
    const languagePopupEl = document?.querySelector('.language-popup')
    if (languagePopupEl) {
        languagePopupEl.classList.add('hide-popup')
    }
}

function removeHidePopupClass() {
    const languagePopupEl = document?.querySelector('.language-popup')
    if (languagePopupEl) {
        languagePopupEl.classList.remove('hide-popup')
    }
}

const selectedRegionLanguagesWithoutSelectedLanguage = computed(
    () =>
        store.selectedRegion?.countryLanguages?.filter(
            cl =>
                cl.languageCharCode?.toLowerCase() !==
                i18n.locale.value
        ) || []
)

const toggleHideClass = () => {
    const languagePopupEl = document?.querySelector('.language-popup')
    if (
        languagePopupEl &&
        !languagePopupEl.classList.contains('hide-popup')
    ) {
        addHidePopupClass()
        openLanguageList.value = false
        return
    }

    removeHidePopupClass()
    openLanguageList.value = true
}
const handleInteraction = () => {
    if (width?.value <= 768) {
        toggleHideClass()
    }
}
</script>

<template>
    <div
        v-if="regionsAsColumns?.length && !store.isLoadingCountries"
        ref="languageSwitcherRef"
        style="position: relative"
        class="lang-wrapper"
        :class="{
            'lang-wrapper-footer': position === 'footer',
            'lang-wrapper-header': position === 'header'
        }"
        @click="handleInteraction"
        @mouseenter="width > 768 ? removeHidePopupClass() : null"
        @mouseleave="width > 768 ? addHidePopupClass() : null"
    >
        <div
            v-if="store.selectedRegion"
            class="header-buttons__region"
            :class="{
                'is-active': openLanguageList,
                'header-buttons__region--footer':
                    position === 'footer',
                'header-buttons__region--footer-global':
                    position === 'footer'
            }"
        >
            <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M12 1.75C9.81196 1.75 7.71354 2.61919 6.16637 4.16637C4.61919 5.71354 3.75 7.81196 3.75 10C3.75 13.1985 5.8243 16.2657 8.04446 18.6092C9.13921 19.7648 10.2365 20.7099 11.0609 21.3664C11.4432 21.6708 11.7656 21.9122 12 22.0825C12.2344 21.9122 12.5568 21.6708 12.9391 21.3664C13.7635 20.7099 14.8608 19.7648 15.9555 18.6092C18.1757 16.2657 20.25 13.1985 20.25 10C20.25 7.81196 19.3808 5.71354 17.8336 4.16637C16.2865 2.61919 14.188 1.75 12 1.75ZM12 23C11.584 23.624 11.5838 23.6239 11.5835 23.6237L11.581 23.6221L11.5748 23.6179L11.5528 23.603C11.5339 23.5902 11.5068 23.5716 11.4719 23.5475C11.4021 23.4992 11.3014 23.4284 11.1742 23.3365C10.9197 23.1527 10.5587 22.8839 10.1266 22.5398C9.2635 21.8526 8.11079 20.8602 6.95554 19.6408C4.6757 17.2343 2.25 13.8015 2.25 10C2.25 7.41414 3.27723 4.93419 5.10571 3.10571C6.93419 1.27723 9.41414 0.25 12 0.25C14.5859 0.25 17.0658 1.27723 18.8943 3.10571C20.7228 4.93419 21.75 7.41414 21.75 10C21.75 13.8015 19.3243 17.2343 17.0445 19.6408C15.8892 20.8602 14.7365 21.8526 13.8734 22.5398C13.4413 22.8839 13.0803 23.1527 12.8258 23.3365C12.6986 23.4284 12.5979 23.4992 12.5281 23.5475C12.4932 23.5716 12.4661 23.5902 12.4472 23.603L12.4252 23.6179L12.419 23.6221L12.4171 23.6233C12.4169 23.6235 12.416 23.624 12 23ZM12 23L12.416 23.624C12.1641 23.792 11.8355 23.7917 11.5835 23.6237L12 23ZM12 7.75C10.7574 7.75 9.75 8.75736 9.75 10C9.75 11.2426 10.7574 12.25 12 12.25C13.2426 12.25 14.25 11.2426 14.25 10C14.25 8.75736 13.2426 7.75 12 7.75ZM8.25 10C8.25 7.92893 9.92893 6.25 12 6.25C14.0711 6.25 15.75 7.92893 15.75 10C15.75 12.0711 14.0711 13.75 12 13.75C9.92893 13.75 8.25 12.0711 8.25 10Z"
                    fill="#2F363D"
                />
            </svg>
            <p
                class="selected-region-lang"
                :style="{
                    color: store.isHeaderFixed ? '#fff' : 'inherit'
                }"
            >
                {{ languageWithCountry }}
            </p>

            <arrow-down class="region-dropdown-arrow"></arrow-down>
        </div>
        <!--        {{ selectedRegionLanguagesWithoutSelectedLanguage }}-->
        <div class="region-language-list">
            <div
                v-for="lang in selectedRegionLanguagesWithoutSelectedLanguage"
                :key="lang.id"
                class="region-language-list__item"
                @click.stop="selectRegion(null, lang)"
            >
                {{ lang.localLanguage }}
            </div>
        </div>
        <!-- language popup -->
        <div
            class="language-popup"
            :class="{
                'language-popup-footer': position === 'footer'
            }"
            :style="{
                top:
                    position === 'header'
                        ? store.isHeaderFixed
                            ? '85px'
                            : '105px'
                        : 'initial'
            }"
        >
            <!-- language search -->
            <div class="language-popup__search">
                <div class="language-popup__search-input-wrapper">
                    <SvgoSearch />
                    <input v-model="term" type="text" />
                </div>
            </div>

            <!-- language list -->
            <div
                v-if="
                    regionsAsColumns?.length &&
                    !store.isLoadingCountries
                "
                :key="numColumns"
                class="language-popup__list language-list"
            >
                <!-- Добавление класса "is-chosen" добавляет голочку справа -->
                <div
                    v-for="(column, index) in regionsAsColumns"
                    :key="index + numColumns"
                    class="language-popup__column"
                >
                    <div
                        v-for="region in column"
                        :key="region.id"
                        class="language-list__item"
                        :class="{
                            'is-chosen':
                                store.selectedRegion?.id ===
                                    region.id &&
                                store.selectedRegion?.id &&
                                region.id
                        }"
                        :data-region="
                            region.id +
                            '-' +
                            region.name +
                            '-' +
                            store.selectedRegion?.name +
                            '-' +
                            store.selectedRegion?.id
                        "
                    >
                        <div class="language-list__title">
                            <NuxtImg
                                :src="region?.icon"
                                :alt="`Language ${!store
                                    .selectedRegion?.name}`"
                                width="14"
                                height="10"
                                class="language-list__icon"
                            />
                            <h5 class="region-name">
                                {{ region.name }}
                            </h5>
                        </div>
                        <div class="language-links">
                            <a
                                v-for="language in region?.countryLanguages"
                                :key="language.languageCharCode"
                                class="language-link"
                                :class="{
                                    'is-active':
                                        selectedLanguageCode ===
                                            language.languageCharCode &&
                                        store.selectedRegion?.id ===
                                            region.id
                                }"
                                @click.stop="
                                    selectRegion(region, language)
                                "
                            >
                                {{
                                    language?.localLanguage ||
                                    language?.languageCharCode ||
                                    'no-localeLang'
                                }}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
.lang-wrapper {
    margin-right: 20px;
    @media screen and (max-width: 768px) {
        margin-right: 0;
    }
}

.language-popup-footer {
    // top: initial;
    // bottom: 115%;
    bottom: 0;
    @include sm-block() {
        left: 0;
    }
}

.header-buttons__region--footer {
    align-items: center;
    border: 1px solid #888;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    max-height: 30px;
    min-width: 80px;
    padding: 7px;
    position: relative;

    .region-dropdown-arrow {
        width: 14px;
        height: 14px;
        fill: #fff !important;

        path {
            fill: #fff !important;
        }
    }

    &.is-active {
        svg {
            path {
                fill: #fff;
            }
        }
    }
}

.selected-region-lang {
    cursor: pointer;
    font-size: 0.75rem;
    font-weight: 500;
    text-transform: uppercase;
    text-wrap: nowrap;
    @include lg-block() {
        font-size: 0.75rem;
    }
}

.region-name {
    text-transform: uppercase;
}

.lang-wrapper-footer {
    margin-right: 0;

    &::before {
        top: -400%;
    }

    .header-buttons__region--footer {
        border-color: #fff;
    }

    .selected-region-lang {
        color: #fff;
    }

    svg {
        path {
            fill: #fff;
        }
    }
}

.region-language-list {
    @include md-block() {
        margin-top: 0;
    }
}
</style>
